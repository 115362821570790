const data = [
  {
    id: 1,
    image: 'https://picsum.photos/50/50?random=1',
    title: 'Quienes somos ?',
    icon: '#',

    description:
      'Somos una empresa dedicada a la nutrición vegetal, con personal técnico capacitado de amplia experiencia y conocimiento de la problemática en Bolivia.',
  },
  {
    id: 2,
    image: 'https://picsum.photos/50/50?random=2',
    icon: '#',
    title: 'Mision',
    description:
      'Nuestra Misión es poner al servicio de la agricultura todos nuestros conocimientos, para desarrollar manejos nutricionales amigables con el ambiente y producir alimentos de manera responsable para nuestra sociedad coadyuvando en la seguridad alimentaria .',
  },
  {
    id: 3,
    image: 'https://picsum.photos/50/50?random=3',
    icon: '#',
    title: 'Vision',
    description:
      'Nuestra Visión es entregar al agro boliviano soluciones nutricionales con tecnologia en el área de nutrición vegetal, para enfrentar los desafíos de la agricultura moderna, enfocados en la sostenibilidad de los sistemas de producción, promoviendo la rentabilidad de los cultivos y preservando el medio ambiente..',
  },
  /* {
    id: 4,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Mejor Diseño Mejor Experiencia',
    description: 'Muestra diseños innovadores y experiencia de usuario únicos',
  },
  {
    id: 5,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Patrones',
    description:
      'Estudio de diseño de patrones para resolución persistentes en el desarrollo web',
  },
  {
    id: 6,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Web App',
    description:
      'El uso de nuevas tecnologías para la resolución de problemas en aplicaciones web',
  }, */
];

export default data;
