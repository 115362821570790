const data = [
  /*  {
    id: 0,
    author: 'Antonio Ayola',
    title: 'Demo post for styles',
    date: '07 de Marzo, 2022',
    banner: 'npm/banner.webp',
    tags: ['demo', 'md', 'markdown'],
    md: 'demo.md',
  }, */
  {
    id: 1,
    author: 'paracuat',
    title: 'HERBICIDA',
    date: '11 de Nov, 2023',
    banner: 'miPrimer/banner.jpg',
    tags: ['blog', 'post', 'hablando', 'portafolio'],
    md: 'miPrimer/miPrimer.md',
  },
  {
    id: 2,
    author: 'carbendazim',
    title: 'FUNGICIDA',
    date: '10 de Marzo, 2022',
    banner: 'ecmascript/banner.jpg',
    tags: ['js', 'javascript', 'ecmascript'],
    md: 'ecmascript/ecmascript.md',
  },
  {
    id: 3,
    author: 'Bifentrin',
    title: 'INSECTICIDA',
    date: '24 de Marzo, 2022',
    banner: 'npm/banner.jpg',
    tags: ['npm', 'node', 'js', 'javascript'],
    md: 'npm/npm.md',
  },
  {
    id: 4,
    author: 'deltafol B-MO',
    title: 'NUTRICION',
    date: '24 de Marzo, 2022',
    banner: 'npm/banner3.jpg',
    tags: ['npm', 'node', 'js', 'javascript'],
    md: 'npm/npm.md',
  },
];

export default data;
