const CATEGORIES = [
  'DEVELOPMENT',
  'WEB DESIGN',
  'APPLICATION',
  'DESIGN',
  'MOBILE',
  'COLABORATION',
];

const data = [
  {
    id: 1,
    year: '2022',
    date: '16/3/2022',
    category: 'WEB DESIGN',
    title: 'Mi Portafolio',
    image: 'portafolio.webp',
    technologies: [
      'PWA',
      'React',
      'CSS Modules',
      'Responsive',
      'Neumorphism',
      'Netlify',
      'Markdown',
      'HTML',
      'JS',
      'react-transition-group',
      'Formspree',
      'Eslint',
      'Prettier',
    ],
    desc: `Mi sitio web personal estilo Neumorfismo.
     Este proyecto me tomo mucho trabajo solo para poder expresar lo que hago y lo que me gusta hacer. 
     Este portafolio es una PWA, asi que puedes instalar la aplicacion en tu dispositivo.
    
     Ademas viene con un pequeño blog usando markdown. Espero que te guste <3.`,
    links: {
      code: 'https://github.com/Tono2007/portafolio',
      page: 'https://antonioayola.netlify.app/',
    },
  },
  {
    id: 2,
    year: '2024',
    date: '10/11/2024',
    category: 'Ultima actualizacion',
    title: 'Soya',
    image: 'carsLandingPage.webp',
    technologies: ['Nutricion Vegetal , Manejo Sostenible'],
    desc: `PRODUCTOS APLICADOS:
* DeltaStim AminoRoots
* DeltaSol Standard MAP 
* DeltaLent Solub 21
* DeltaSol Standad SOP`,
    links: '#',
  },
  {
    id: 3,
    year: '2024',
    date: '10/11/2024',
    category: 'Ultima actualizacion',
    title: 'Soya',
    image: 'movieApp.webp',
    technologies: ['Nutricion Vegetal , Manejo Sostenible'],
    desc: `PRODUCTOS APLICADOS:
* DeltaStim AminoRoots
* DeltaFol Active
* DeltaFol B-Mo
* DeltaFol Brix
    `,
    links: '#',
  },
  {
    id: 4,
    year: '2022',
    date: '04/5/2022',
    category: 'WEB DESIGN',
    title: 'LOL-APP',
    image: 'lolApp.webp',
    technologies: [
      'ReactJs',
      'Styled-Components',
      'HTML',
      'CSS',
      'JS',
      'GSAP',
      'Netlify',
      'Responsive',
      'Riot API',
      'Axios',
    ],
    desc: `Este proyecto es una aplicación para consultar jugadores y detalles de los campeones del juego.
    Ocupando Styled Components para el diseño y la API de RIOT para obtener información de los jugadores.
    Este proyecto fue práctica para aprender Styled Components y la biblioteca GSAP para las animaciones.
    La clave que tengo para acceder a la api de riot es limitada, en cuanto numero de peticiones y el tiempo que estara activa, puede presentar errores antes que genere otra.
    El despliegue del sitio esta en Netlify.
    `,
    links: {
      code: null,
      //page: 'https://lol-app.netlify.app/',
      page: 'https://master--lol-app.netlify.app/',
    },
  },
  {
    id: 5,
    year: '2024',
    date: '10/11/2024',
    category: 'Ultima actualizacion',
    title: 'Maiz',
    image: 'pokedex.webp',
    technologies: ['Nutricion Vegetal , Manejo Sostenible'],
    desc: `PRODUCTOS APLICADOS:
* DeltaStim AminoRoots
* DeltaFol Actice
* DeltaFol B-Mo`,
    links: '#',
  },
  {
    id: 6,
    year: '2022',
    date: '11/11/2022',
    category: 'APPLICATION',
    title: 'React-Libs',
    image: 'reactLibs.webp',
    technologies: ['ReactJs', 'JS', 'Docusaurus ', 'Vercel', 'Responsive'],
    desc: `Para este proyecto queria intentar generar una documentación, me encontre con varias herramientas que ya se encargan de un desarrollo rapido para centrarnos en el contenido.
    Se eligío Docusaurus 2, un moderno generador de sitios estaticos, especializado en documentación ademas de estar construido con React.
    
    Utilizando el preset(classic) de Docusaurus fue muy sencillo generar la estructura de documentación. Se puede personalizar el diseño, crear tus componentes y seguir ocupando React, ademas que el contenido se escribe en mdx.

    El tema que se escogio para llenar con algo fue generar una base de datos con información util, consejos, guias en el uso de React.
    Este proyecto fue muy sencillo, el proposito solo fue agregar una herramienta mas a mis conocimientos que en algun momento puede ser util.
    `,
    links: {
      code: 'https://github.com/Tono2007/React-Libs',
      page: 'https://react-libs.vercel.app/',
    },
  },
  {
    id: 7,
    year: '2024',
    date: '10/11/2024',
    category: 'Ultima actualizacion',
    title: 'Arroz',
    image: 'slimMuiTemplate.webp',
    technologies: ['Nutricion Vegetal , Manejo Sostenible'],
    desc: `PRODUCTOS APLICADOS:
* DeltaFol Active
* DeltaFol B-Mo
 DeltaStim AminoRoots
* DeltaFol Brix
         `,
    links: '#',
  },
  {
    id: 101,
    year: '2023',
    date: '06/03/2023',
    category: 'COLABORATION',
    title: 'DustBusters',
    image: 'dustBusters.webp',
    technologies: ['ReactJs', 'Typescript', 'Tailwind ', 'Java', 'Spring Boot'],
    desc: `Proyecto colaborativo desarrollado durante el cohorte8 de No Country, simulación de entorno de trabajo con duranción de 5 semanas, con un equipo de 8 personas donde participe como frontend developer consiguiendo un avance satisfactorio.
    Buenisimo para mejorar abilidades blandas y hacer amistades nuevas.

    El proyecto consistió en Una plataforma de servicio de limpieza que permita a los clientes solicitar y programar servicios de limpieza en su hogar o lugar de trabajo. 
    `,
    links: {
      code: 'https://github.com/No-Country/c9-32-t-javareact',
      page: 'https://dust-busters.netlify.app',
    },
  },
  {
    id: 102,
    year: '2023',
    date: '30/04/2023',
    category: 'COLABORATION',
    title: 'CompraListo',
    image: 'compraListo.webp',
    technologies: ['ReactJs', 'JS', 'Tailwind ', 'Java', 'Spring Boot'],
    desc: `Proyecto colaborativo desarrollado durante el seleccionado7 de No Country, simulación de entorno de trabajo con duranción de 5 semanas, con un equipo de 9 personas donde participe como frontend developer.

    El proyecto consistió en web app para crear, guardar y compartir tus listas de compras. Puedes ingresar desde cualquier dispositivo, crearte una cuenta y empezar a crear tus listas, compartir con tus familiares, generar un pdf o compartir por whatsapp marcar los productos que ya tienes en el supermercado y hasta te calcula cuando vas a gastar. 
    `,
    links: {
      code: 'https://github.com/No-Country/s7-11-t-javareact',
      page: 'https://compralisto0.vercel.app/',
    },
  },
  {
    id: 103,
    year: '2023',
    date: '10/06/2023',
    category: 'COLABORATION',
    title: 'Pigmeo',
    image: 'pigmeo.jpg',
    technologies: ['ReactJs', 'Typescript', 'JS', 'Tailwind '],
    desc: `Proyecto colaborativo desarrollado durante el seleccionado8 de No Country, simulación de entorno de trabajo con duranción de 5 semanas, con un equipo de 12 personas donde participe como frontend developer.

    El proyecto consistió en web app mobile responsive desarrollada para brindar a los usuarios un control total de sus finanzas y facilitar la realización de diversas transacciones de forma rápida y segura.

    -> https://www.youtube.com/watch?v=UJ5vFleryLM
    `,
    links: {
      code: 'https://github.com/No-Country/s8-14-t-mern',
      page: 'https://pigmeo-app.netlify.app/',
    },
  },
];

export default data;
