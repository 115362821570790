/* import user from '../../assets/testimonial/appdt.webp'; */

const data = [
  {
    id: 0,
    profileImg: 'appdt.jpg',
    company: 'Tecnomyl',
    name: 'Ing. lucas gustavo carvajal barrios',
    level: 'Gerente general',
    work: 'Empresa quimica',
    date: '15 de Abril, 2022',
    testimony: `En Tecnomyl dedicamos importantes recursos humanos, materiales y de tiempo, a desarrollar y proveer adecuadas soluciones a los problemas fitosanitarios que enfrentan los agricultores latinoamericanos: Amplio y atractivo portafolio de productos de elevada calidad..`,
  },
  {
    id: 1,
    profileImg: 'juanFranciscoIA.jpeg',
    company: 'Agropartners',
    name: 'Ing. gary daniel padilla',
    level: 'Ejecutivo',
    work: 'Empresa quimica y soporte',
    date: '28 de Diciembre, 2021',
    testimony: `Agropartners tiene como objetivo contribuir a una agricultura de calidad mundial y preservar el encanto de trabajar la tierra. La empresa promueve el aprendizaje continuo y un ambiente competitivo, con el fin de ayudar a sus colaboradores a alcanzar su mejor versión.`,
  },
  /* 
  {
    id: 2,
    profileImg: '',
    company: 'APPDT Software',
    name: 'Miguel Mendez',
    level: 'CEO',
    work: 'Programador Analista',
    testimony:
      'Cabe mencionar que el trabajo que Antonio realizo fue muy certero creando un excelente equipo de trabajo',
  },
  {
    id: 3,
    profileImg: '',
    company: 'APPDT Software',
    name: 'Miguel Mendez',
    level: 'CEO',
    work: 'Programador Analista',
    testimony:
      'Cabe mencionar que el trabajo que Antonio realizo fue muy certero creando un excelente equipo de trabajo',
  },
  {
    id: 4,
    profileImg: '',
    company: 'APPDT Software',
    name: 'Miguel Mendez',
    level: 'CEO',
    work: 'Programador Analista',
    testimony:
      'Cabe mencionar que el trabajo que Antonio realizo fue muy certero creando un excelente equipo de trabajo',
  }, */
];
export default data;
